import { useState } from "react";
import classNames from "classnames";
import {SidebarProps} from "./types";
import { Image } from "../images";
import { customIcon } from "../../consts/custom-icons/customIcon";

export const SidebarItem = ({
  title,
  number,
  productName,
  icon,
  singleItemClassNames,
  border,
  imageUrl,
  hideEmptyImage,
  pageName,
  onClickEvent
}: SidebarProps) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <li
      onClick={() => onClickEvent(title)}
      className={classNames(
        { "border-b-[12px] 2md:border-0 border-white" : border && pageName !== "configurator"},
        { "border-0 border-black-charcoal 2md:border-0" : (border && pageName === "configurator") },
      )}
    >
      <div className={classNames(
        { "opacity-100 visible": showImage },
        { "2md:opacity-0 2md:invisible": !showImage },
        {"hidden": hideEmptyImage},
        "relative 2md:absolute h-80 sm:h-[500px] 2md:h-full 2md:w-1/2 left-0 top-0 cursor-pointer transition-opacity duration-500 ease-in-out",
      )}>
        {imageUrl && <Image className={classNames({ "object-bottom" : number === "01" })} height="100%" width="100%" objectFit="cover" src={imageUrl} alt="Segment" />}
      </div>
      <div
        className={classNames(
          "flex w-full justify-between items-center cursor-pointer relative w-100 after:content-none px-7 xl:px-0  2md:after:content-evenly after:absolute 2md:after:w-full after:h-full after:bg-transparent",
          singleItemClassNames,
          { "text-dark-grey-light80" : !border },
          { "text-dark-grey-light80 border-b border-black-charcoal" : border },
        )}
        onMouseEnter={() => setShowImage(true)}
        onMouseLeave={() => setShowImage(false)}
      >
        <div className="relative">
          <p className={classNames(
            "text-xl md:pl-0",
            {"pl-0" : pageName === "configurator"}
          )}>
            {number}
          </p>
          <p
            className={classNames(
              "text-3xl2 md:pl-0",
              {"pl-0" : pageName === "configurator"}
            )}
          >
            {title}
          </p>
        </div>
        {(productName && number == "01") && <p className='pt-3 pl-6 min-w-fit text-4xl '>{productName}</p>}
        {icon && (
          <div
            className={classNames(
              { "opacity-100": showImage || imageUrl},
              { "2md:opacity-0": !showImage && imageUrl },
              "transition-opacity duration-700 ease-in-out",
            )}>
            {customIcon[icon]}
          </div>
        )}
      </div>
    </li>
  );
};
