import { LogoProps } from "./types";
import classNames from "classnames";
import { logo } from "../../consts/logo";
import useAppSizeControl from "../../utils/useAppSizeControl";

const app = document.getElementById(process.env.REACT_APP_ID as string) as HTMLElement ?? undefined ;
const header = document.querySelector(".header") as HTMLElement;
const initialHeight = header?.clientHeight ?? 0;

export const Logo = ({
  children,
  paddingLeftAndRight,
  paddingTopAndBottom,
  width,
  height
}: LogoProps) => {
  const [isHidden, setIsHidden] = useAppSizeControl(app, header, initialHeight);

  function onClickEvent(e: any) {
    e.preventDefault();
    if (!header) {
      return;
    }

    app.style.position = "";
    app.style.top = "";
    app.style.left = "";
    app.style.width = "";
    app.style.height = "";
    app.style.bottom = "";
    app.style.right = "";
    app.style.zIndex = "";
    app.style.overflowY = "";
    header.style.top = "";
    header.style.overflow = "";
    header.style.height = `${initialHeight}px`;
    header.style.transition = "all 0.3s linear 0s";
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsHidden(true);
  }

  return !isHidden ? (
    <button className={classNames(paddingLeftAndRight, paddingTopAndBottom, width, height, "cursor-pointer logo")}
      onClick={onClickEvent}>
      {typeof children === "undefined" ? logo.Default : children}
    </button>
  ) : <div />;
};
