import { useEffect, useState } from "react";
import mergeImages from "../utils/mergeImages";

// Convert image from URL to base64 using mergeImages.
function convertImgToDataURLviaMergeImages(url: string, callback?: CallableFunction) {
  if (!callback) {
    return;
  }
  if (url === "" || typeof url === "undefined" || !url) {
    callback(null);
    return;
  }

  mergeImages([{ src: url, x: 0, y: 0}], {crossOrigin: "anonymous", scale:0.5}).then((src) => {
    callback(src);
  }).catch(()=>{
    callback(null);
  });
}

function makeVariantsPDFReady(variants: any[], onFinish: CallableFunction) {
  if (variants?.length === 0) {
    return [];
  }
  let allVarsCount = 0;
  const filtered: any[] = [];
  for (let idx = 0; idx <= variants.length; idx++) {
    const v = variants[idx];

    if(!v || !v.assets){
      continue;
    }

    convertImgToDataURLviaMergeImages(v.assets[0]?.preview_image_url, (src: any) => {
      v.image = src;

      filtered.push(v);
      allVarsCount++;

      if (allVarsCount === variants.length) {
        onFinish(filtered);
      }
    });
  }

  return [];
}

function useGeneratePdf(variants: any[]) {
  const [generatingPdf, setGeneratingPdf] = useState<boolean>(true);
  const [downloadingImages, setDownloadingImages] = useState<boolean>(false);
  const [filteredVariants, setFilteredVariants] = useState<any[]>();

  useEffect(() => {
    if(!downloadingImages){
      setDownloadingImages(true);
      setTimeout(() => {
        makeVariantsPDFReady(variants, (fVs: any[]) => {
          setFilteredVariants(fVs);
          setGeneratingPdf(false);
        });
      }, 2000);
    }
  });

  return {
    generatingPdf,
    filteredVariants
  };
}

export default useGeneratePdf;