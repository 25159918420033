export enum VarianButtonEnum {
  DEFAULT = "default",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TEXTBUTTON = "textButton",
  ICONBUTTON = "iconButton",
  SECONDARYICONBUTTON = "secondaryIconButton",
  NEXTBUTTON = "nextButton",
  SENDBUTTON ="sendButton",
  BACKBUTTON ="backButton"
}
