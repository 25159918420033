import {Props, VariantItemProps} from "./types";
import classNames from "classnames";
import { useState, useEffect, useRef, useContext } from "react";
import SwiperCore, { Navigation, Pagination, Mousewheel} from "swiper";
import dataContainer from "../../services/DataContainer";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import { AdobeCampaignService } from "../../services/adobe/AdobeCampaign";
import { AdobeEventType } from "../../enum/adobe/adobeEventType";
import { ConfiguratorOptions } from "../../enum/enums";
import { useParams } from "react-router-dom";
SwiperCore.use([Navigation, Pagination, Mousewheel]);

export const VariantItemSwiperColors = (props: Props) => {
  const {
    activeComponent,
    defaultVariantColor,
    isColorComingFromURL } = useContext(DataContainerContext);
  const [variantState, setVariantState] = useState<[]>();
  const [triggerVariation, setTriggerVariation] = useState(false);
  const adobeService = new AdobeCampaignService();
  const [variantKey, setVariantKey] = useState<any>();
  const modelName = dataContainer.getActualModelName();
  const prevVariantName = adobeService.usePrevious(variantKey);
  const [preventFromSelectingDefault, setPreventFromSelectingDefault] = useState(true);
  const [colorObj, setColorObj] = useState<VariantItemProps | undefined>(undefined);

  useEffect(() => {
    if (activeComponent.name) {
      const currentSegment = localStorage.getItem("segment");
      const variations = dataContainer.findComponentVariations(activeComponent.group_id);
      const sectionKey =
        `${modelName}_${currentSegment}_DISABLED_OPTIONS`.toLocaleLowerCase();
      const variationsToSkip = dataContainer
        .sectionKey("Segment.Settings", sectionKey)
        .split(",");
      const trimmedVariationsToSkip = variationsToSkip.map((s: string) => s.trim());

      const filteredVariations = variations.filter((variation: any) => {
        if(trimmedVariationsToSkip.includes(variation.original_key)){
          return false;
        }
        else if(variation.dependencies?.length > 0){
          const chosenVariationBasedOnDependencyOfParentVariation = variation.dependencies
            .find((dependency: any) => dataContainer.isVariationSelected(dependency.depends_on_variation_id));
          return chosenVariationBasedOnDependencyOfParentVariation ? true : false;
        }
        return true;
      });
      setVariantState(filteredVariations);
      const colors = dataContainer.findComponentVariations(activeComponent.group_id);
      const containDefaultSelection = colors?.every((color: VariantItemProps) => !color.is_default);
      if (activeComponent.name === ConfiguratorOptions.COLORS &&
                containDefaultSelection &&
                !isColorComingFromURL &&
                preventFromSelectingDefault) {
        containDefaultSelection && colors.map((color: VariantItemProps) => {
          // if (color.key === ConfiguratorOptions.AGRA_RED_7518) {
          //   dataContainer.toggleVariationSelection(color.id);
          // }
        });
        setPreventFromSelectingDefault(false);
      }
      if (isColorComingFromURL && preventFromSelectingDefault) {
        const isColorValid = colors.find((color: VariantItemProps) => color.key === defaultVariantColor);
        if (!isColorValid) {
          const agraRedColor = colors.find((color: VariantItemProps) => color.key === ConfiguratorOptions.AGRA_RED_7518);
          dataContainer.toggleVariationSelection(agraRedColor.id);
        }
        setPreventFromSelectingDefault(false);
      }
    }
  }, [activeComponent.name]);

  useEffect(() => {
    dataContainer.mLoadedData && adobeService.sendAnalytics(AdobeEventType.VARIANT, {
      data: prevVariantName
    });
  }, [variantKey]);

  useEffect(() => {
    setColorObj(dataContainer.findComponentVariations(activeComponent.group_id)?.find((component: VariantItemProps) => dataContainer.isVariationSelected(component.id)));
  }, [dataContainer.mSelectedVariations]);

  const triggerVariationClick = (variant: VariantItemProps) => {
    props.updateCurrentView(!triggerVariation);
    const attribute = dataContainer.findAttributeFromAssets(variant.key);
    if(attribute) dataContainer.markAttributeAsSelected(attribute);
    !dataContainer.isVariationSelected(variant.id) && dataContainer.toggleVariationSelection(variant.id);
    dataContainer.findComponentVariations(activeComponent.group_id).forEach((component: VariantItemProps) => {
      dataContainer.isVariationSelected(component.id) && setColorObj(component);
    });
  };

  return (
    <div>
      {colorObj && <>
        <div className="bg-gray-lighter before:h-px before:w-full before:block"></div>
        <div className="text-center py-3 text-xs">
          <span className="text-font-vonum-bold">{dataContainer.sectionKey(`Options.${activeComponent.name}`, `${colorObj.original_key}_title`)} </span>
          <span className="uppercase">{dataContainer.sectionKey(`Options.${activeComponent.name}`, `${colorObj.original_key}`)}</span>
        </div>
      </>}
      <div className='border border-t border-gray-lighter'>
        <div dir="rtl" className="relative h-[90px] overflow-x-hidden ml-2" id="color-scrollbar">
          <div dir="ltr" className="h-full grid xl:grid-cols-12 lg:grid-cols-8 md:grid-cols-6 sm:grid-cols-4 grid-cols-3 gap-0 pl-2.5">
            {variantState?.map((variant: VariantItemProps) => (
              <div className="" id={"color-variation"} key={variant.id} onClick={() => {
                setVariantKey(variant.key);
                triggerVariationClick(variant);
              }} >
                <div className={classNames("flex hover:cursor-pointer items-center border-r border-b py-0 h-20 xl:h-24 border-gray-lighter justify-center", variant.className)}>
                  <div style={{backgroundImage : "url(" + variant?.assets[0]?.preview_image_url + ")"}}
                    className={classNames("w-16 p-px rounded-full", variant.className,
                      {"background-none" : !dataContainer?.isVariationSelected(variant.id)} )}>
                    <img
                      src={variant?.assets[0]?.preview_image_url}
                      alt={variant.key}
                      className={classNames("w-full h-full sm:w-24 break-words object-contain border-4 border-white rounded-full", variant.className)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
