import { useEffect, useRef, useState } from "react";
import { Props } from "./types";
import classNames from "classnames";
import DataContainer from "../../services/DataContainer";
import { ContactDealerFormSettings } from "../../enum/enums";

export const Input = ({
  type,
  variant,
  value,
  isValid = true,
  isRequired,
  onChangeEvent,
  name,
  label,
  checked,
  onKeyDownEvent,
  onClickEvent,
  customClassNames,
}: Props) => {

  const [toggleClassLabel, setToggleClassLabel] = useState(false);
  const getInputs = useRef(null);
  const getLabels = useRef(null);
  const returnClass = (isActive: boolean) => {
    Object.entries(getInputs).forEach((input: HTMLInputElement[]) => {
      if (input[1] !== null) {
        if (input[1].value !== "") {
          Object.entries(getLabels).forEach((label: HTMLLabelElement[]) => {
            if (label[1] !== null) {
              if (label[1].htmlFor === input[1].name) {
                label[1].classList.remove("-translate-y-spacing-h-xl");
                label[1].classList.add("-translate-y-spacing-h-lg", "uppercase", "text-blue", "text-xl");
              }
            }
          });
        }
      }
    });
    if (isActive) {
      return "uppercase text-blue text-xl -translate-y-spacing-h-lg";
    }
    return "-translate-y-spacing-h-xl";
  };

  return (
    <div
      className={classNames(
        {
          "bg-primary": variant === "primary",
          "bg-white": variant === "secondary",
          "bg-transparent": variant === "mailForm",
        },
        "relative justify-end cursor-pointer",
        { "flex flex-row flex-row-reverse flex-reverse items-center text-lg text-light-grey flex-start": type === "radio" },
        { "w-full h-full flex flex-col": type !== "checkbox" && type !== "radio" },
        { "flex flex-row-reverse items-center": type === "checkbox" },
      )}
    >
      <label
        ref={getLabels}
        className={classNames(
          customClassNames,
          variant !== "default" && returnClass(toggleClassLabel),
          { "absolute transition ease-in-out": variant !== "default" },
          { "font-normal items-center normal-case text-2xs": type === "checkbox" },

          {"flex" : type === "radio"}
        )}
        htmlFor={name}
      >
        {label}
      </label>
      <input
        ref={getInputs}
        id={name}
        type={type}
        className={classNames(
          customClassNames,
          {
            "h-16 bg-transparent pt-5 leading-6 pb-4 pl-2 focus:outline-none border-b border-black":
              variant !== "default",
          },
          { "border border-light-grey px-2 py-2": variant === "default" },
          { "mb-4": isValid },
          { " mr-1": type === "checkbox" },
          {"border rounded-full border-blue-400 mb-0 mr-2 px-1.5 py-1.5 scale-100": type === "radio"}
        )}
        value={type === "radio" ? label : value}
        onChange={onChangeEvent}
        onClick={onClickEvent}
        name={name}
        checked={checked}
        onKeyDown={onKeyDownEvent}
        required={isRequired}
        onFocus={() => {
          variant !== "default" && setToggleClassLabel(true);
        }}
        onBlur={() => {
          if (variant !== "default" && value === "") setToggleClassLabel(false);
        }}
      />
      {(isRequired && value === "") && (
        <span className="text-red text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM,`contact_dealer_${name}_required_text`)}</span>
      )}
    </div>
  );
};
