import { useState, useEffect } from "react";

function useWindowHeight() {
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  
  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        setWindowHeight(window.innerHeight);
        setWindowWidth(window.innerWidth);
      }, 2000);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);

  }, []); 
  // return values only for screens smaller than 'md' breakpoint
  return windowWidth <= 640 ? windowHeight : null;
}

export default useWindowHeight;