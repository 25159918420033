import {ChangeEvent, useEffect, useState} from "react";
import { colorButton } from "../../enum/Button/colorButton.model";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { alphabets, ContactDealerSettings, ContactDealerFormSettings} from "../../enum/enums";
import DataContainer from "../../services/DataContainer";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import { Props } from "./types";

function CaptchaForForms({ captcha, required, resetCaptcha, setCorrectCaptcha, correctCaptcha}: Props) {
  const [captchaText, setCaptchaText] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  const getLetters = () => alphabets.letters[Math.floor(Math.random() * alphabets.letters.length)];
  const getNumbers = () => Math.floor(Math.random() * 10);

  const generateCaptcha = () => {
    const captcha = `${getLetters()}${getNumbers()}${getNumbers()}${getLetters()}${getLetters()}${getNumbers()}`;
    setCaptchaText(captcha);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCaptchaValue(e.target.value);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  useEffect(() => {
    if(captchaValue === captchaText) {
      captcha(true);
      setCorrectCaptcha(true);
    } else {
      captcha(false);
      setCorrectCaptcha(false);
    }
  }, [captchaValue, captchaText]);

  useEffect(() => {
    if (captchaValue) {
      setCaptchaValue("");
      generateCaptcha();
    }

  }, [resetCaptcha]);
  
  return (
    <div className="relative flex flex-col direction-start">
      <svg viewBox="0 0 1152 700" fill="none" height="50px" width="100px">
        <path id="wave-text-3" d="M-27 525C568 243 896 399 99999 198C8000 364 120 430 231 445" /> 
        <text>
          <textPath
            fill="#1d1d1d"
            className="line-through text-[300px] font-black select-none"
            startOffset="0" href="#wave-text-3"
          >
            {captchaText}
          </textPath>
        </text>
      </svg>
      <div className="relative flex flex-col pb-3">
        <Input
          variant="primary"
          type="text"
          name={ContactDealerSettings.NAME}
          label={ContactDealerSettings.CAPTCHA_LABEL}
          value={captchaValue}
          isRequired={required}
          onChangeEvent={onChange}
        />
        <Button
          variant={VarianButtonEnum.ICONBUTTON}
          bgColor={colorButton.TRANSPARENT}
          customClassNames="absolute top-3 -right-3"
          icon={IconModelEnum.RELOAD}
          onClickEvent={() => generateCaptcha()}
        />
        {(required && captchaValue !== captchaText && captchaValue !== "" && !correctCaptcha) &&
          <span className="text-red text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_WRONG_CAPTCHA_TEXT, ContactDealerFormSettings.CONTACT_DEALER_WRONG_CAPTCHA_TEXT)}</span>
        }
      </div>
    </div>
  );
}

export default CaptchaForForms;
