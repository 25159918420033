type objectList = {
  [key: string]: object,
};

export const customIcon: objectList = {
  Arrow: (
    <svg
      fill="#182972"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="14px"
    >
      <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z" />
    </svg>
  ),
  Search: (
    <svg
      fill="#404040"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="18"
      height="18"
    >
      <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z" />
    </svg>
  ),
  ArrowRightBold: (
    <svg
      width="20"
      height="20"
      className="svg-inline--fa fa-arrow-right fa-w-14"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="arrow-right"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      data-fa-i2svg=""
    >
      <path
        fill="#f5f5f5"
        d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
      />
    </svg>
  ),
  Done: (
    <svg
      fill="#1251B5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="15px"
      height="15px"
    >
      <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
    </svg>
  ),
};
