export const logo = {
  Default: (
    <svg
      fill="none"
      height="7"
      viewBox="0 0 76 7"
      width="76"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path

        d="M1.28721 1.33089L3.90246 6.08349L6.93157 6.08509L9.50279 1.33009L10.7916 1.33089V0.46875H6.91476V1.33009H8.03467L6.16229 4.79228L4.26509 1.33089L5.62515 1.33009V0.46875H0.000800505L0 1.33089H1.28721Z"
        fill="black"
      />
      <path

        d="M34.6141 1.32849L34.6133 0.46875L39.7998 0.469551V1.32849H38.5077V5.20774H39.7053C40.8148 5.20774 41.5424 4.47448 41.5424 3.48025H42.3982V6.07789L34.6133 6.07869V5.20934H35.9205L35.9189 1.32849H34.6141Z"
        fill="black"
      />
      <path

        d="M22.0381 0.351562C19.4341 0.351562 18.0508 1.65078 18.0508 3.28061C18.0508 4.93365 19.3828 6.25129 22.0533 6.25129C24.723 6.25129 26.0406 4.93365 26.0406 3.28061C26.0406 1.65078 24.6429 0.351562 22.0381 0.351562ZM22.0093 5.35952C21.0583 5.34271 20.6404 4.29165 20.658 3.24699C20.6772 2.16551 21.1944 1.22251 22.0853 1.23772C22.9763 1.25373 23.447 2.21434 23.4278 3.29582C23.4094 4.31566 22.9883 5.37713 22.0093 5.35952Z"
        fill="black"
      />
      <path

        d="M51.2452 1.33089L53.8613 6.08349L56.8904 6.08509L59.4608 1.33009L60.7496 1.33089V0.46875H56.8736V1.33009H57.9927L56.1211 4.79228L54.2231 1.33089L55.584 1.33009V0.46875H49.9596L49.958 1.33089H51.2452Z"
        fill="black"
      />
      <path

        d="M71.9971 0.351562C69.393 0.351562 68.0098 1.65078 68.0098 3.28061C68.0098 4.93365 69.3418 6.25129 72.0123 6.25129C74.682 6.25129 75.9996 4.93365 75.9996 3.28061C75.9996 1.65078 74.6019 0.351562 71.9971 0.351562ZM71.9683 5.35952C71.0173 5.34271 70.5994 4.29165 70.617 3.24699C70.6362 2.16551 71.1534 1.22251 72.0443 1.23772C72.9353 1.25373 73.4052 2.21434 73.386 3.29582C73.3691 4.31566 72.9481 5.37713 71.9683 5.35952Z"
        fill="black"
      />
    </svg>
  ),
};
