export const buttonVariant = {
  default: "cursor-pointer",
  primary: "bg-blue py-3.5 w-60 cursor-pointer text-white text-xs",
  secondary: "bg-white py-3.5 px-10 cursor-pointer text-blue text-xs",
  textButton: "py-3.5 px-10 cursor-pointer text-dark-grey text-xs",
  iconButton:
    "bg-dark-grey py-3.5 px-3.5 flex justify-center items-center cursor-pointer text-white",
  secondaryIconButton:
    "bg-dark-grey flex justify-center items-center cursor-pointer border-none text-white",
  nextButton: "bg-blue py-3.5 w-24 cursor-pointer text-white text-xs tracking-widest",
  backButton: "bg-white py-3.5 w-24 cursor-pointer text-blue font-bold text-xs tracking-widest",
  sendButton:
    "bg-blue-light py-3.5 w-40 md:w-48 cursor-pointer text-white text-xs",
};
