/* eslint new-cap: ["error", { "properties": false }] */

// Defaults
const defaultOptions = {
	format: 'image/png',
	quality: 0.92,
	width: undefined,
	height: undefined,
	Canvas: undefined,
	crossOrigin: undefined,
	scale: undefined
};

const createCanvas = options =>
	options.Canvas ?
		new options.Canvas.Canvas() :
		window.document.createElement('canvas');

const createImage = options =>
	options.Canvas ?
		options.Canvas.Image :
		window.Image;

const edonateSrc = (src) => {
	return src + `${!src.includes("?")?'?':'&'}image-merger`;
}

// Return Promise
const mergeImages = (sources = [], options = {}) => new Promise(resolve => {
	options = Object.assign({}, defaultOptions, options);

	// Setup browser/Node.js specific variables
	const canvas = createCanvas(options);
	const Image = createImage(options);
	if (options.Canvas) {
		options.quality *= 100;
	}

	// Load sources
	const images = sources.map(source => new Promise((resolve, reject) => {
		// Convert sources to objects
		if (source.constructor.name !== 'Object') {
			source = { src: source };
		}

		if (source.width && source.height && !options.scale) {
			const img = new Image(source.width, source.height);
			img.crossOrigin = options.crossOrigin;

			img.onerror = () => reject(new Error('Couldn\'t load image'));
			img.onload = () => {
				const { width, height } = source;
				if((img.naturalWidth === width && img.naturalHeight === height) || (img.naturalWidth === 1 && img.naturalHeight === 1)){
					resolve(Object.assign({}, source, { img: img }));
					return;
				}

				const canvas = createCanvas(options);
				const ctx = canvas.getContext('2d');

				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);

				// Adjust source image width and height
				const resizeImg = new Image(source.width, source.height);
				resizeImg.crossOrigin = options.crossOrigin;
				resizeImg.onerror = () => {
					console.error("Couldn't load image"); 
					resolve(Object.assign({}, source, { img: img }));
				};
				resizeImg.onload = () => {
					resolve(Object.assign({}, source, { img: resizeImg }))
				};
				resizeImg.src = canvas.toDataURL();
			};
			img.src = edonateSrc(source.src);
		}if(options.scale){
			const img = new Image();
			img.crossOrigin = options.crossOrigin;
			img.onerror = () => reject(new Error('Couldn\'t load image'));
			img.onload = () => {
				const canvas = createCanvas(options);
				const ctx = canvas.getContext('2d');

				const width = img.naturalWidth*options.scale;
				const height= img.naturalHeight*options.scale;

				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);

				// Adjust source image width and height
				const resizeImg = new Image();
				resizeImg.crossOrigin = options.crossOrigin;
				resizeImg.onerror = (e) => {
					// console.error("Couldn't load image", e); 
					resolve(Object.assign({}, source, { img: img }));
				};
				resizeImg.onload = () => resolve(Object.assign({}, source, { img: resizeImg }));
				resizeImg.src = canvas.toDataURL();
			};
			img.src = edonateSrc(source.src);
		} 
		else {
			// Resolve source and img when loaded
			const img2 = new Image();
			img2.crossOrigin = options.crossOrigin;
			img2.onerror = () => reject(new Error('Couldn\'t load image'));
			img2.onload = function() { 
				if (source.width && source.height 
					&& this.naturalWidth !== source.width && this.naturalHeight !== source.height){
					const { width, height } = source;
					const canvas = createCanvas(options);
					const ctx = canvas.getContext('2d');

					canvas.width = width;
					canvas.height = height;
					ctx.drawImage(img2, 0, 0, width, height);

					// Adjust source image width and height
					const resizeImg = new Image(source.width, source.height);
					resizeImg.crossOrigin = options.crossOrigin;
					resizeImg.onerror = () => {
						console.error("Couldn't load image"); 
						resolve(Object.assign({}, source, { img: img }));
					};
					resizeImg.onload = () => {
						resolve(Object.assign({}, source, { img: resizeImg }))
					};
					resizeImg.src = canvas.toDataURL();
				}else{
					resolve(Object.assign({}, source, { img: img2 }));
				}
			};
			img2.src = edonateSrc(source.src);
		}
	}));

	// Get canvas context
	const ctx = canvas.getContext('2d');

	// When sources have loaded
	resolve(Promise.all(images)
		.then(images => {
			// Set canvas dimensions
			const getSize = dim => options[dim] || Math.max(...images.map(image => image.img[dim]));
			canvas.width = getSize('width');
			canvas.height = getSize('height');

			// Draw images to canvas
			images.forEach(image => {
				ctx.globalAlpha = image.opacity ? image.opacity : 1;
				return ctx.drawImage(image.img, image.x || 0, image.y || 0);
			});

			if (options.Canvas && options.format === 'image/jpeg') {
				// Resolve data URI for node-canvas jpeg async
				return new Promise((resolve, reject) => {
					canvas.toDataURL(options.format, {
						quality: options.quality,
						progressive: false
					}, (err, jpeg) => {
						if (err) {
							reject(err);
							return;
						}
						resolve(jpeg);
					});
				});
			}

			// Resolve all other data URIs sync
			return canvas.toDataURL(options.format, options.quality);
		}));
});

export default mergeImages;