import classNames from "classnames";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { Button } from "../Button/Button";
import { Typography } from "../typography";
import { Links, Props } from "./types";
import DataContainer from "../../services/DataContainer";
import { DisclaimerTexts } from "../../enum/enums";

export const DisclaimerSection = ({
  links,
  positionOfLinks,
  positionOfContent,
  positionOfTitle,
  isSummary,
}: Props) => {
  return (
    <div
      className={classNames(
        "w-full flex flex-col justify-center md:py-10 py-14 md:px-0 px-12 text-secondary",
        { "items-center": !isSummary }
      )}
    >
      <div
        className={classNames("w-full text-xl", {
          "lg:text-left text-center": positionOfTitle === "left",
          "text-right": positionOfTitle === "right",
          "text-center": positionOfTitle === "center",
          "md:w-2/4": !isSummary,
        })}
      >
        <Typography tag="h4">
          {Object.keys(DataContainer.mApplicationData).length !== 0 ? DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.DISCLAIMER_TITLE) : DisclaimerTexts.MARKETS_DISCLAIMER_TITLE}
        </Typography>
      </div>
      <div
        className={classNames("text-xs text-secondary w-full pt-3", {
          "lg:text-left text-center": positionOfContent === "left",
          "text-right": positionOfContent === "right",
          "text-center": positionOfContent === "center",
          "md:w-2/4": !isSummary,
        })}
      >
        {Object.keys(DataContainer.mApplicationData).length !== 0 ? DataContainer.sectionKey(DisclaimerTexts.MAIN_DISCLAIMER, DisclaimerTexts.DISCLAIMER_TEXT) : DisclaimerTexts.MARKETS_DISCLAIMER_TEXT}
      </div>
      <div
        className={classNames("flex w-full flex-wrap", {
          "lg:justify-start justify-center": positionOfLinks === "left",
          "justify-end": positionOfLinks === "right",
          "justify-center": positionOfLinks === "center",
          "md:w-2/4": !isSummary,
        })}
      >
        {links.map((item: Links) => (
          <Button
            key={item.title}
            onClickEvent={() => window.open(item.link)}
            variant={VarianButtonEnum.TEXTBUTTON}
            text={item.title}
            disclaimer={true}
          />
        ))}
      </div>
    </div>
  );
};
