export enum AugmentedRealityEnum {
  EXTERIOR = "Exterior",
  CHASSIS = "Chassis",
  PRODUCT = "product",
  LANGUAGE = "lang",
  COLORS = "Colors",
  HEADLIGHTS = "Headlights",
  INT_TRIM_LEVELS = "Int_trim_levels",
  HD_EXT_TRIM_LEVELS = "Trim_levels",
  EXT_TRIM_LEVELS = "Ext_trim_levels",
  INTERIOR = "Interior",
  CABS = "Cabs",
  SEATS = "Seats",
}
