import {Props} from "../Loader/types";

export const Loader = ({
  text,
}: Props) => {
  return (
    <div className="flex absolute z-50 flex-col items-center justify-center w-full h-full bg-white text-black">
      <div>{text ? text : "Loading..."}</div>
    </div>
  );
};
