import { AugmentedRealityEnum } from "../../enum/augmented-reality/augmentedReality";
import {
  AvailableVariations,
  ComponentModel,
  ComponentsGroupModel,
} from "../../pages/Configurator/configurator.model";
import dataContainer from "../DataContainer";
import {
  AugmentedRealityMobileToWebModel,
  AugmentedRealityWebToMobileModel,
} from "./augmentedReality-model";

export const buildConfiguratorFromURL = (
  searchParams: {[key: string]: string},
  componentGroups: ComponentsGroupModel[]
) => {
  let state: AugmentedRealityMobileToWebModel[] = [];
  Object.entries(searchParams).forEach((queryObject: string[]) => {
    if (
      queryObject[0] !== AugmentedRealityEnum.PRODUCT &&
      queryObject[0] !== AugmentedRealityEnum.LANGUAGE
    ) {
      state = [
        ...state,
        {
          componentGroup:
            queryObject[0] ===
            `${AugmentedRealityEnum.EXTERIOR}-${AugmentedRealityEnum.CHASSIS}`
              ? AugmentedRealityEnum.CHASSIS
              : queryObject[0].split("-")[0],
          component: queryObject[0].split("-")[1],
          variant: queryObject[1],
        },
      ];
    }
  });
  state.map((item: AugmentedRealityMobileToWebModel) => {
    componentGroups.map((componentGroup: ComponentsGroupModel) => {
      if (componentGroup.name === item.componentGroup) {
        componentGroup.components.map((component: ComponentModel) => {
          if (component.name === item.component) {
            component.available_variations.map(
              (variant: AvailableVariations) => {
                if (variant.key === item.variant) {
                  !dataContainer.isVariationSelected(variant.id) && 
                  dataContainer.toggleVariationSelection(variant.id);
                }
              }
            );
          }
        });
      }
    });
  });

  const isTractor = searchParams["Exterior-Chassis"]?.startsWith("tra_");
  const chassisTypeVariation = dataContainer.findVariationByOriginalKey(isTractor ? "tractor" : "rigid");
  if(chassisTypeVariation) {
    dataContainer.toggleVariationSelection(chassisTypeVariation.id);
  }
};

export const buildURLfromSelectedVariants = (
  lang: string,
  model: string,
  hash: string,
  componentGroupsObject: ComponentsGroupModel[]
) => {
  let state: AugmentedRealityWebToMobileModel[] = [];
  let link = `VolvoTrucksARBuilder://?product=${model.split("_")[0]}`;
  const componentQueryForURL = [
    AugmentedRealityEnum.COLORS,
    AugmentedRealityEnum.INT_TRIM_LEVELS,
    AugmentedRealityEnum.EXT_TRIM_LEVELS,
    AugmentedRealityEnum.HEADLIGHTS,
    AugmentedRealityEnum.CHASSIS,
    AugmentedRealityEnum.SEATS,
    AugmentedRealityEnum.CABS
  ];
  dataContainer.mSelectedVariations.forEach((selectedVariantID: number) => {
    componentGroupsObject?.forEach((componentGroup: ComponentsGroupModel) => {
      componentGroup.components.forEach((componentObject: ComponentModel) => {
        componentQueryForURL.forEach((componentName: string) => {
          if (componentObject.name === componentName) {
            componentObject.available_variations.forEach(
              (currentVariant: AvailableVariations) => {
                if (selectedVariantID === currentVariant.id) {
                  state = [
                    ...state,
                    {
                      groupName: componentGroup.name,
                      componentName: componentObject.name,
                      variantKey: currentVariant.key,
                    },
                  ];
                }
              }
            );
          }
        });
      });
    });
  });
  state.forEach((item: any) => {
    if (item.groupName === AugmentedRealityEnum.CHASSIS) item.groupName = AugmentedRealityEnum.EXTERIOR;
    link += `&${item.groupName}-${item.componentName}=${item.variantKey}`;
  });
  link += `&lang=${lang}`;
  return link.toLocaleLowerCase();
};