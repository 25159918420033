import classnames from "classnames";
import { kindOfIconButton } from "../../consts/Button/iconConstButton";
import { buttonVariant } from "../../consts/Button/variantConstButton";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { Icon, Props, Variant } from "./types";

export const Button = ({
  text,
  icon,
  variant,
  bgColor,
  textColor,
  border,
  rounded = true,
  textTransform = true,
  fullWidth = false,
  onClickEvent,
  customClassNames,
  customClassNamesSpan,
  disclaimer,
  type = "button",
  onMobileView
}: Props) => {
  const variantClassName = (
    element: VarianButtonEnum,
    objectVariant: Variant,
  ) => objectVariant[element];
  const iconOfButton = (element: IconModelEnum, objectIcon: Icon) =>
    objectIcon[element];

  return (
    <button
      type={type}
      onClick={onClickEvent}
      className={classnames(
        variantClassName(variant, buttonVariant),
        { "w-full": fullWidth, "rounded-3xl": rounded, border: border },
        "flex justify-center items-center w-max outline-none",
        customClassNames,
        bgColor
      )}
    >
      {icon && <span>{iconOfButton(icon, kindOfIconButton)}</span>}
      {text && (
        <span
          className={classnames(
            { "ml-2": icon,
              capitalize: !textTransform,
              "uppercase": !disclaimer,
              "text-small text-gray-suva hover:underline font-normal": disclaimer,
              "hidden sm:inline" : onMobileView
            },
            textColor,
            customClassNamesSpan,
          )}
        >
          {text}
        </span>
      )}
    </button>
  );
};
