export const useWrap = (min: number, max: number, v: number): number => {
  let breachNum = 0;
  if(v > max){
    breachNum = max;
    return breachNum;
  }
  else if(v < min){
    breachNum = min;
    return breachNum;
  }
  return v;
};