import React, { createContext, useEffect, useReducer, useState } from "react";
import { ActiveComponent, ActiveComponentGroups } from "../../pages/Configurator/configurator.model";
import { loadApplicationData, loadData } from "../../services/ApiService";
import dataContainer from "../../services/DataContainer";
import { ApplicationDataModel, CurrentLocaleModel, DataContainerModel } from "./DataContainer.model";

const ProductReducer = (state: { countComponent: number; }, action: { type: string, payload: number; }) => {
  switch (action.type) {
  case "incrementComponent":
    return { ...state, countComponent: state.countComponent + action.payload };
  case "decrementComponent":
    return { ...state, countComponent: state.countComponent - action.payload };
  case "setComponentCount":
    return { ...state, countComponent: action.payload };
  case "resetComponent":
    return { countComponent: 0 };
  default:
    return state;
  }
};

export const DataContainerContext = createContext<any>(null);
export const DataContainerProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [data, setData] = useState<any>([]);
  const [currentLocale, setCurrentLocale] = useState<CurrentLocaleModel>();
  const [activeComponent, setActiveComponent] = useState<ActiveComponent>({ name: "", group_id: 0, index: 0 });
  const [activeComponentGroups, setActiveComponentGroups] = useState<ActiveComponentGroups>({ name: "", id: 0, index: 0 });
  const initialStateComponent = { countComponent: activeComponent.index};
  const [stateOfComponent, dispatchStateOfComponent] = useReducer(ProductReducer, initialStateComponent);
  const [componentArrayLength, setComponentArrayLength] = useState(0);
  const [modelName, setModelName] = useState("");
  const [searchParams, setSearchParams] = useState<string[]>();
  const [defaultVariantColor, setDefaultVariantColor] = useState("");
  const [isColorComingFromURL, setIsColorComingFromURL] = useState(false);

  useEffect(() => {
    if (currentLocale) {
      loadData(currentLocale).then((res: DataContainerModel) => {
        loadApplicationData(currentLocale).then((app: ApplicationDataModel) => {
          dataContainer.mApplicationData = app.data.data;          
          dataContainer.mProducts = res.data.data;
          setData(res.data.data);
        });
      });
    }
  }, [currentLocale]);

  //this needs to be refactored in the future
  useEffect(() => {
    if (dataContainer.mLoadedData) {
      if (activeComponentGroups.name === "Interior") {
        setComponentArrayLength(dataContainer.getComponentByComponentGroupsId(activeComponentGroups.id).length + 1);
      } else {
        setComponentArrayLength(dataContainer.getComponentByComponentGroupsId(activeComponentGroups.id).length -1);
      }
    }
  }, [activeComponent, activeComponentGroups]);

  return (
    <DataContainerContext.Provider
      value={{ 
        data,
        currentLocale,
        setCurrentLocale,
        activeComponent,
        setActiveComponent,
        activeComponentGroups,
        setActiveComponentGroups,
        stateOfComponent,
        dispatchStateOfComponent,
        componentArrayLength,
        setComponentArrayLength,
        modelName,
        setModelName,
        searchParams,
        setSearchParams,
        defaultVariantColor,
        setDefaultVariantColor,
        isColorComingFromURL,
        setIsColorComingFromURL
      }}
    >
      {children}
    </DataContainerContext.Provider>
  );
};
