export enum IconModelEnum {
    MESSAGE = "Message",
    MESSAGEBLACK = "MessageBlack",
    SUBMIT = "Submit",
    SHARE = "Share",
    PDF = "pdf",
    LOADING = "Loading",
    JPG = "jpg",
    PNG = "png",
    MENU = "Menu",
    CLOSE = "Close",
    CLOSE_GREY = "CloseGrey",
    BOLD_CLOSE = "BoldClose",
    ARROWRIGHT = "ArrowRight",
    ARROWLEFT = "ArrowLeft",
    RELOAD = "Reload",
    INFO = "Info",
    TICK = "Tick",
    FACEBOOK = "Facebook",
    FACEBOOKSECONICON = "FacebookSecondIcon",
    STAR = "Star",
    AR = "AR",
    AppStore = "AppStore",
    GoogleStore = "GoogleStore",
    RealImage360 = "RealImage360",
    StaticInteriorImage = "StaticInteriorImage"
}