import React, { useEffect, useState, useContext } from "react";
import { Logo } from "../../components/Logo/Logo";
import { Typography } from "../../components/typography";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { Button } from "../../components/Button/Button";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { DisclaimerSection } from "../../components/Disclaimer-Section/DisclaimerSection";
import { useNavigate } from "react-router-dom";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import DataContainer from "../../services/DataContainer";
import { getLocales } from "../../services/ApiService";

function Markets() {
  const navigate = useNavigate();
  const [locale, setLocale] = useState("en-en");
  const [localeID, setLocaleID] = useState("270");
  const [listLocale, setListLocale] = useState([]);
  const { setCurrentLocale } = useContext(DataContainerContext);
  useEffect(() => {
    getLocales().then((res) => {
      setListLocale(res.data.data);
    });
  }, []);
  return (
    <div className="container mx-auto px-4 sm:px-0">
      <Logo paddingTopAndBottom="py-14 px-6 xl:px-0" />
      <div className="flex content-center justify-center"> 
        <div className="md:w-1/2 md:pb-20">
          <Typography tag="h4" className="md:text-3xl2 text-lg text-center">
            Please select your country/market to configure your truck according
            to your local market product offerings and availability. Confirm
            exact specifications, features and availability by contacting your
            local Volvo Trucks dealer
          </Typography>
          <div className="py-10 md:w-1/2 w-4/5 mx-auto text-center items-center">
            <Dropdown
              selectedOption={locale}
              variant="secondary"
              label="SELECT MARKET"
              labelTransitions={false}
              onChangeEvent={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setLocale(e.currentTarget.value);
                setLocaleID(e.target.options[e.currentTarget.selectedIndex].id);
              }}
              toggleTextCenter
              options={listLocale}
            />
          </div>
          <div className="flex justify-center">
            <Button
              variant={VarianButtonEnum.PRIMARY}
              onClickEvent={() => {
                setCurrentLocale({ locale: locale, localeID: localeID });
                DataContainer.logEvent("page_entered", "segment", {product: ""});
                navigate(`/${locale}`);
              }}
              text="CONFIRM"
            />
          </div>
        </div>
      </div>
      <div className="w-4/5 text-center mx-auto text-primary pt-0 sm:pt-20 lg:pt-0">
        <DisclaimerSection
          links={[
            {
              title: "Privacy",
              link: "https://truckbuilder-9000.conf.prod.rapidimages.net/",
            },
            {
              title: "Cookies",
              link: "https://truckbuilder-9000.conf.prod.rapidimages.net/",
            },
          ]}
          positionOfLinks="center"
        />
      </div>
    </div>
  );
}

export default Markets;
