export const customBreakpoints = {
  320: {
    slidesPerView: 1.1,
    spaceBetween: 8,
  },
  640: {
    slidesPerView: 1.5,
    spaceBetween: 8,
  },
  840: {
    slidesPerView: 2,
    spaceBetween: 16,
  },
  1100: {
    slidesPerView: 3,
    spaceBetween: 16,
  },
  1500: {
    slidesPerView: 4,
    spaceBetween: 16,
  },
  1800: {
    slidesPerView: 4.5,
    spaceBetween: 16,
  },
};