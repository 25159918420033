import classNames from "classnames";
import { TypographyProps } from "./types";

export const Typography = ({
  children,
  tag,
  className,
}: TypographyProps): JSX.Element => {
  const Tag = tag;

  return <Tag className={classNames(className)}>{children}</Tag>;
};
