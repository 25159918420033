import classnames from "classnames";
import { useContext, useEffect } from "react";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import { MainTopMenus } from "../../enum/enums";
import dataContainer from "../../services/DataContainer";
import { Link, Props } from "./types";

export const Menu = ({
  links,
  paddingX,
  paddingY,
  onClickEvent
}: Props) => {
  const { 
    activeComponent,
    setActiveComponent,
    setActiveComponentGroups,
    activeComponentGroups
  } = useContext(DataContainerContext);
  useEffect(() => {
    if (dataContainer.mLoadedData) {
      const { component_groups }: any = dataContainer.mLoadedData;
      if (activeComponent.name === "" && activeComponentGroups.name === "") {
        setActiveComponentGroups({
          name: component_groups[0]?.name,
          id: component_groups[0]?.id,
          index: 0,
        });
        setActiveComponent({
          name: component_groups[0]?.components[0]?.name,
          group_id: component_groups[0]?.components[0]?.id,
        });
      }
    }
  },[dataContainer.mLoadedData, activeComponentGroups]);

  return (
    <div className="inline-block">
      {links.map((item: Link, index: number) => (
        <button 
          key={index}
          onClick={() => {
            onClickEvent({
              name: item.name,
              group_id: item.id ? item.id : 0,
              index: index
            }); 
          }}
          className={classnames(
            "uppercase text-xl",
            { "opacity-100": item.name === activeComponent?.name},
            { "opacity-50": item.name !== activeComponent?.name},
            { "px-7": !paddingX },
            paddingX,
            { "py-5": !paddingY },
            paddingY,
          )}
        >
          { item.name && !location.href.includes("/summary/") ?
            dataContainer.sectionKey(MainTopMenus.MAIN_TOPMENU, `${activeComponentGroups.name.toLowerCase()}_${item.name.toLowerCase()}_title`) : 
            item.name }
        </button>
      ))}
    </div>
  );
};
