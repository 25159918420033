import { RenderConfiguration } from "./useRenderOnDemand.types";
import dataContainer from "../services/DataContainer";

import { useLocation, useParams } from "react-router";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { useContext } from "react";
import { ConfiguratorOptions } from "../enum/enums";

const useRenderConfig = () => {
  const productId = dataContainer.getCurrentModelId();
  const configuration: Record<string, Record<string, string>> = {};
  const {activeComponentGroups} = useContext(DataContainerContext);
  const currentComponentGroup = activeComponentGroups.name;
  const {modelName} = useParams();
  const {modelNameFromUrl} = useParams();
  const currentProductName = modelName ? modelName : modelNameFromUrl;
  const location = useLocation();

  const allComponents = dataContainer.getAllComponents()?.filter((component: { name: string; }) => component.name !== ConfiguratorOptions.CHASSI_TYPE);
  const selectedVariations = allComponents?.map((component: { name: any; id: number}) => dataContainer.findSelectedVariantByComponentNameAndComponentGroup(component.name, dataContainer.findComponentGroupByComponentId(component.id).name));

  const interiorSelectedVariations = selectedVariations && selectedVariations.filter((variant: any) => dataContainer.findComponentGroupByName(variant?.component_group_name)?.name === ConfiguratorOptions.INTERIOR);
  const exteriorSelectedVariations = selectedVariations && selectedVariations.filter((variant: any) => dataContainer.findComponentGroupByName(variant?.component_group_name)?.name !== ConfiguratorOptions.INTERIOR);

  let getOriginalKeysAndComponentNames;

  const mirrorsForInterior = dataContainer.findSelectedVariantByComponentNameAndComponentGroup(ConfiguratorOptions.MIRRORS, ConfiguratorOptions.EXTERIOR)?.original_key;
  const cabsForInterior = dataContainer.findSelectedVariantByComponentNameAndComponentGroup(ConfiguratorOptions.CABS, ConfiguratorOptions.EXTERIOR)?.original_key;

  if (currentComponentGroup === ConfiguratorOptions.INTERIOR && !location.pathname.includes("/summary")) {
    getOriginalKeysAndComponentNames = interiorSelectedVariations.map((el: { component_id: number; original_key: string; }) => {
      const componentName = dataContainer.findComponentById(el?.component_id)?.name;              
      return {
        originalKey: el?.original_key,
        componentName: componentName,
      };
    });
    configuration[ConfiguratorOptions.INTERIOR] = {};
    configuration[ConfiguratorOptions.INTERIOR][ConfiguratorOptions.MIRRORS] = mirrorsForInterior;
    configuration[ConfiguratorOptions.INTERIOR][ConfiguratorOptions.CABS] = cabsForInterior;
    getOriginalKeysAndComponentNames.forEach((singleKeyNamePair: { componentName: string | number; originalKey: string; }) => {              
      configuration[ConfiguratorOptions.INTERIOR][singleKeyNamePair.componentName] = singleKeyNamePair.originalKey;          
    });
  } else {
    getOriginalKeysAndComponentNames = exteriorSelectedVariations?.map((el: { component_id: number; original_key: string; }) => {
      const componentName = dataContainer.findComponentById(el?.component_id)?.name;              
      return {
        originalKey: el?.original_key,
        componentName: componentName,
      };
    });
    configuration[ConfiguratorOptions.EXTERIOR] = {};
    getOriginalKeysAndComponentNames?.forEach((singleKeyNamePair: { componentName: string | number; originalKey: string; }) => {              
      configuration[ConfiguratorOptions.EXTERIOR][singleKeyNamePair.componentName] = singleKeyNamePair.originalKey;          
    });
  }
  
  const renderSpec: RenderConfiguration = {
    productId,
    componentGroups: configuration,
    productName: currentProductName?.toUpperCase()
  };
  
  return renderSpec;
};

export default useRenderConfig;