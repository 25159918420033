import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { first, Subject } from "rxjs";
import { AdobeEventType } from "../../enum/adobe/adobeEventType";
import DataContainer from "../DataContainer";
import { AdobeCampaignInterface } from "./adobeCampaign.model";

export const subCategory$ = new Subject<string>();
export const category$ = new Subject<string>();
export const _allPreviousChoice: string[] = [];

let prevSubCategory = "";
let prevCategory = "";

export class AdobeCampaignService {
  _eventType = "";
  _customType = "";
  _body: AdobeCampaignInterface = {};
  _params = useParams();

  public constructor() {
    subCategory$.pipe(first()).subscribe((subCategory: string) => {
      prevSubCategory = subCategory;
    });
    category$.pipe(first()).subscribe((category: string) => {
      prevCategory = category;
    });
  }

  usePrevious(value: string) {
    const ref: any = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  setAllPreviousChoice(item: any) {
    _allPreviousChoice.push(item);
  }

  setSubCategory(subCategory: string) {
    subCategory$.next(subCategory);
  }

  setCategory(category: string) {
    category$.next(category);
  }

  sendAnalytics = (eventType: string, target: any, allPreviousChoice?: any) => {
    this._eventType = eventType;
    if (eventType === AdobeEventType.VARIANT) {
      this._customType = target.data;
      this._body = {
        category: prevCategory,
        data: target.data,
        subcategory: prevSubCategory,
        segment: localStorage.getItem("segment")!,
        product: this._params.modelName,
      };
      this.setAllPreviousChoice([
        { customType: this._customType },
        { eventType: this._eventType },
        { body: this._body },
      ]);
    } else if (eventType === AdobeEventType.PAGE_ENTERED) {
      this._customType = target.category;
      this._body = {
        product: this._params.modelName,
      };
      this.setAllPreviousChoice([
        { customType: this._customType },
        { eventType: this._eventType },
        { body: this._body },
      ]);
    }
    DataContainer.analyticTrackEvent(
      this._eventType,
      this._customType,
      this._body,
      allPreviousChoice
    );
  };
}
