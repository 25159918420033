import { useState } from "react";
import classNames from "classnames";
import { Props } from "./types";
import { Typography } from "../typography";
import {Image} from "../images";

export const ModelItem = ({
  image,
  alt,
  title,
  bgColor,
  onClickEvent,
}: Props) => {
  const [hoverImg, setHoverImg] = useState(false);

  return (
    <div 
      style={{ backgroundColor: bgColor }}
      onClick={onClickEvent}
      onMouseEnter={() => setHoverImg(true)}
      onMouseLeave={() => setHoverImg(false)}
      className="flex flex-col lg:gap-20 sm:flex-row items-center p-14 sm:p-100px cursor-pointer border-b border-gray-light"
    >
      <div className="relative model-item h-model-height w-model-width items-center overflow-hidden flex-none">
        <Image
          className={classNames(
            "absolute left-0 h-full top-0 object-cover",
            { "object-right": hoverImg},
            { "object-left": !hoverImg},
          )}
          src={image}
          alt={alt}
        />
      </div>
      <div className="model-title">
        <Typography tag="h3" className="text-3xl2">{title}</Typography>
      </div>
    </div>
  );
};
