import React from "react";

export interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
}

const Spinner = ({ size = 18, ...props }: SpinnerProps) => (
  <div {...props} className={"flex items-center justify-center w-full max-h-full"}>
    <svg
      viewBox="20 20 40 40"
      className={"text-black origin-center animate-spinner"}
      width={`${size}px`}
      height={`${size}px`}
    >
      <circle cx="40" cy="40" r="18" stroke="#000" strokeWidth={3} strokeDasharray={50} strokeLinecap="round" fill="none" className={"stroke"}></circle>
    </svg>
  </div>
);

export default Spinner;