import { useEffect, useRef, useContext, useState } from "react";
// import { RodContext, RodProvider } from "../common/context/RodProvider"; //may not need it
import DataContainer from "../services/DataContainer";
import { ImageStatus, ImageRequest, RenderInfo, defaultRenderInfo } from "./useRenderOnDemand.types";
import { Buffer } from "buffer";


const useRenderOnDemand = (specification: ImageRequest): RenderInfo => {  
  const interval = useRef<ReturnType<typeof setTimeout> | undefined>();

  // const { getImages, setImages } = useContext(RodContext); //may not need it 

  const [cache, setCache] = useState<Record<string, RenderInfo>>({});

  const getImages = (hash: string) => {
    let info = cache[hash];

    if(!info) {
      info = { ...defaultRenderInfo };
      setCache({ ...cache, [hash]: info});
    }

    return info;
  };

  const setImages = (hash: string, info: RenderInfo) => {
    setCache({ ...cache, [hash]: info });
  };

  const maxTries = 14;  
  const buffer = Buffer.from(JSON.stringify(specification), "utf8");  
  const hash = buffer.toString("base64");    
  const imageInfo = getImages(hash);

  const prevHash = useRef<string>(hash);

  useEffect(() => {
    if (imageInfo.status === ImageStatus.Timeout && hash != prevHash.current) {
      const newInfo = { ...imageInfo };
      newInfo.status = ImageStatus.None;
      newInfo.urlAssets.length = 0;
      setImages(hash, newInfo);
    }
  }, [hash, imageInfo.status]);

  useEffect(() => {
    prevHash.current = hash;
  }, [hash]);

  useEffect(() => {
    if (!imageInfo.urlAssets.length) {
      if (imageInfo.status == ImageStatus.None) {
        const request = specification;
        DataContainer.getRender(request)
          .then((status) => {
            const data = status.data;
            if (data) {
              const info = {
                ...data,
                tries: 0
              };
              setImages(hash, info);
            }
          })
          .catch((error: any) => {
            console.error(error);
          });

        const newInfo = { ...imageInfo, status: ImageStatus.Fetching };
        setImages(hash, newInfo);
      } else if (imageInfo.status == ImageStatus.Queued) {        
        let timeoutPeriod = 500;
        if (imageInfo.tries == 1) {
          timeoutPeriod = 4000;
        }
        interval.current = setTimeout(() => {
          DataContainer.checkStatus(imageInfo)
            .then((status) => {
              const timedOut = (imageInfo.tries + 1 > maxTries) && (status.data.status !== ImageStatus.Ready);
              const info = {
                ...status.data,
                status: timedOut ? ImageStatus.Timeout : status.data.status,
                tries: imageInfo.tries + 1
              };
              setImages(hash, info);
            })
            .catch((error: any) => {
              console.error(error);
            });
        }, timeoutPeriod);
      }
    }
    return () => {
      clearTimeout(interval.current);
    };
  }, [imageInfo]);

  return imageInfo;
};

export default useRenderOnDemand;