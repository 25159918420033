import { Props } from "./types";
import { Logo } from "../Logo/Logo";
import { Menu } from "../Menu/Menu";
import { Button } from "../Button/Button";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { useEffect, useRef, useState, useContext } from "react";
import classNames from "classnames";
import { List } from "../List/List";
import DataContainer from "../../services/DataContainer";
import { MainButtons, MainTopMenus } from "../../enum/enums";

export const Header = ({ title, onClickEvent, links, onSidebarItemClickEvent, listSidebar, refToScroll}: Props) => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const sidebarElement = useRef<any>(null);

  useEffect(() => {
    const Slider = document.querySelector(".main-slider");

    const checkIfClickedOutside = (e: Event) => {
      if (
        toggleSidebar &&
        sidebarElement.current &&
        !sidebarElement.current.contains(e.target)
      ) {
        setToggleSidebar(false);
      }
    };
    addEventListener("mousedown", checkIfClickedOutside);
    Slider?.addEventListener("click",() => toggleSidebar && setToggleSidebar(false));
    return () => {
      removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [toggleSidebar,listSidebar]);  

  return (
    <div className="flex justify-between items-center border-b border-b-primary p-6">
      <Logo />
      <div className="hidden-vtg 2md:!flex !visible">
        <Menu 
          links={links} 
          onClickEvent={(item) => {
            if (item.name === DataContainer.sectionKey(MainTopMenus.MAIN_TOPMENU, MainTopMenus.OVERVIEW_TITLE)) {
              refToScroll.current.scrollIntoView({behavior: "smooth"});
            } else {
              onClickEvent(item);
            }
          }} 
        />
      </div>
      <span className="flex 2md:hidden uppercase text-xl px-7">{title}</span>
      <div className="flex items-center">
        <span className="hidden 2md:!flex !visible uppercase text-xl px-7">{title === "engineAndTransmission" ? "ENGINE AND TRANSMISSION" : title}</span>
        <Button
          variant={VarianButtonEnum.ICONBUTTON}
          icon={IconModelEnum.MENU}
          customClassNames="bg-gray-light"
          onClickEvent={() => setToggleSidebar(!toggleSidebar)}
        />
      </div>
      <div
        ref={sidebarElement}
        className={classNames(
          "absolute top-0 right-0 z-40 h-full overflow-y-hidden transform duration-300 ease-in-out",
          {
            "translate-x-0 opacity-100 lg:w-1/2 xl:w-1/2 w-full transition-transform transition-delay-0": toggleSidebar,
          },
          {
            "translate-x-full w-0 opacity-0 z-50 transition-all transition-delay-300": !toggleSidebar,
          }
        )}
      >
        <div className="absolute h-20 pt-4 z-50 w-sidebar-close pl-8 bg-dark-grey flex items-center">
          <Button
            variant={VarianButtonEnum.ICONBUTTON}
            onClickEvent={() => setToggleSidebar(false)}
            icon={IconModelEnum.CLOSE}
            customClassNames="border border-black-charcoal"
          />
          <p className="pl-5 text-dark-grey-light80">
            {DataContainer.sectionKey(MainButtons.MAIN_BUTTONS, MainButtons.SIDEBAR_CLOSE_BUTTON)}
          </p>
        </div>
        <List
          onClickItemEvent={({name,id,index}) => {
            onSidebarItemClickEvent({name: name, id: id, index: index});
            setToggleSidebar(false);
          }}
          items={listSidebar}
          toggleSidebar={setToggleSidebar}
          pageName="configurator"
          paddingX="12"
          singleItemClassNames="py-0 h-24"
          className="h-full md:px-24 pt-24 overflow-y-auto z-50"
          hideEmptyImage={true}
        />
      </div>
    </div>
  );
};
