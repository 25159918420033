import React from "react";
import { RodSettings } from "../../enum/enums";
import DataContainer from "../../services/DataContainer";
const ErrorMessage = () => {
  return (
    <div>
      <div className="h-full w-full flex justify-center items-center">
        <h1>
          {DataContainer.sectionKey(
            RodSettings.ROD_SETTINGS,
            RodSettings.ROD_ERROR
          )}
        </h1>
      </div>
    </div>
  );
};

export default ErrorMessage;
