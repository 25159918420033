import classNames from "classnames";
import { useState } from "react";
import { Props, MarketsOption } from "./types";
import DataContainer from "../../services/DataContainer";
import { ContactDealerFormSettings } from "../../enum/enums";

export const Dropdown = ({
  options,
  label,
  onChangeEvent,
  name,
  isRequired,
  selectedOption,
  toggleTextCenter,
  variant = "primary",
  labelTransitions = true,
}: Props) => {  
  
  const [toggleClassLabel, setToggleClassLabel] = useState(false);
  return (
    <div
      className={classNames("cursor-pointer flex flex-col relative py-4", {
        "bg-primary": variant === "primary",
        "bg-transparent": variant === "secondary",
      })}
    >
      <label
        className={classNames(
          "h-6 text-grey-dark",
          { "text-center": toggleTextCenter },
          { "text-xl text-blue uppercase -translate-y-spacing-h-sm": (toggleClassLabel && labelTransitions) }
        )}
      >
        {label}
      </label>
      <select
        name={name}
        onChange={onChangeEvent}
        className={(`${!name ? "py-2.5" : "py-0"} px-1 bg-transparent border-b border-black focus-visible:outline-none`)}
        onFocus={() => setToggleClassLabel(true)}
        onBlur={(e) => !e.currentTarget.value && setToggleClassLabel(false)}
        value={selectedOption}
      >
        <option className="hidden"/>
        {options.map((option: MarketsOption, index: number) => (
          option.locale !== "en" && 
          <option
            className={classNames({ "text-center": toggleTextCenter })}
            key={option.id || index}
            value={option.locale && option.locale}
            id={option.id && option.id}
          >
            {option.language ? option.language : option}
          </option>
        ))}
      </select>
      {(isRequired && selectedOption === "") && (
        <span className="text-red text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM,`contact_dealer_${name === "salesforceSegment" ? "segment" : name}_required_text`)}</span>
      )}
    </div>
  );
};
