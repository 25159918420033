import { Props } from "./types";

import { Pannellum } from "pannellum-react";

export const PanoramicViewTpl = ({ imageUrl, pitch, yaw }: Props) => {
  return (
    <Pannellum
      width="100%"
      image={imageUrl}
      pitch={pitch}
      yaw={yaw}
      hfov={100}
      autoLoad
      showZoomCtrl={false}
      mouseZoom={false}
      minHfov={100}
      maxHfov={100}
    />
  );
};
